<template>
  <div id="layout">
    
      <!-- <menu-bar></menu-bar> -->
      <search-view></search-view>
      <!-- <footer-bar></footer-bar> -->

  </div>
</template>

<script>
// Layout components
import MenuBar from "@/views/site/blocks/MenuBar";
import FooterBar from "@/views/site/blocks/FooterBar";
import SearchView from "@/views/site/search/SearchView";

export default {
  name: "NewSearch",
  data() {
    return {
      route_name: null,
    };
  },
  components: {
    MenuBar,
    FooterBar,
    SearchView,
    FooterBar
  },
  created(){
  },
};
// Layout components
</script>
<style scoped>
.header-container{
    display: block !important;
}
</style>